const vi={
    jiantizhongwen:'Tiếng Việt',
    xianlu:'đường kẻ',
    dianwei:'điểm hiện tại',
    wodezichan:'tài sản của tôi',
    touzikuaixun:'thông tin đầu tư',
    jiaoyiyonghu:'người dùng giao dịch',
    jiaoyipinglei:'Danh mục giao dịch',
    danlunhuoli:'lợi nhuận vòng đơn',
    jiaoyiriqi:'Ngày Giao dịch',
    dengluhouchakan:'Đăng nhập để xem',
    shouye:'trang đầu',
    jiaoyidating:'sàn giao dịch',
    yucun:'đặt trước',
    zoushi:'xu hướng',
    wode:'của tôi',
    zuiditouzi:'đầu tư tối thiểu',
    zuidijinru:'nhập cảnh tối thiểu',
    jichulicai:'phòng chính',
    zishenlicai:'phòng trung cấp',
    dashilicai:'Phòng Superior',
    zhuanjialicai:'Phòng thủ tướng',
	基础理财:'phòng chính',
    资深理财:'phòng trung cấp',
    大师理财:'Phòng Superior',
    专家理财:'Phòng thủ tướng',
    huiyuanquanyi:'lợi ích thành viên',
    zuoriyingli:'lợi nhuận của ngày hôm qua',
    zongzichan:'Tổng tài sản',
    chucunjin:'Tiết kiệm tiền',
    jinriyingli:'lợi nhuận hôm nay',
    zhuanchu:'chuyển ra ngoài',
    cunru:'tiền gửi',
    zhanghuxinxni:'thông tin tài khoản',
    lishizhangdan:'hóa đơn lịch sử',
    zijinminxi:'báo cáo quỹ',
    renwuzhongxin:'trung tâm nhiệm vụ',
    xiaoxigonggao:'thông báo tin tức',
    tuijianfenxiang:'Đề nghị chia sẻ',
    huodongzhongxin:'Trung tâm hoạt động',
    jibenziliao:'thông tin cơ bản',
    dengluzhanghao:'đăng nhập tài khoản',
    nicheng:'tên nick',
    shezhi:'cài đặt',
    genggaixianlu:'thay đổi lộ trình',
    guanyuwomen:'về chúng tôi',
    xitongshezhi:'Cài đặt hệ thống',
    yuyanshezhi:'cài đặt ngôn ngữ',
    tuichudenglu:'đăng xuất',
    bangdingyinhangka:'Liên kết thẻ ngân hàng',
    bangdingxuniqianbao:'Liên kết ví ảo',
    anquanma:'mã bảo vệ',
    xiugailenglumima:'Thay đổi mật khẩu đăng nhập',
    yinhangkaguanli:'quản lý thẻ ngân hàng',
    tianjiayinhangka:'thêm thẻ ngân hàng',
    xuniqianbaoguanli:'Quản lý ví ảo',
    tianjiaxuniqianbao:'Thêm ví ảo',
    chiakren:'chủ thẻ',
    yinhangkakahao:'số tài khoản ngân hàng',
    yinhangmingcheng:'tên ngân hàng',
    yhkTps:'Vui lòng liên kết thẻ ngân hàng của chính chủ thẻ',
    nixiqianbaodizhi:'địa chỉ ví ảo',
    xuniqianbaoleixing:'Loại ví ảo',
    xnqbTps:'Nhắc nhở: Vui lòng điền loại địa chỉ USDT, chẳng hạn như: trc20, erc20, omni.',
    plchikaren:'Vui lòng nhập tên chủ thẻ',
    plkahao:'Vui lòng nhập đúng số tài khoản ngân hàng',
    plyinhangmingcheng:'Vui lòng nhập tên ngân hàng',
    plqbdz:'Vui lòng nhập địa chỉ ví ảo',
    plqblx:'Vui lòng nhập loại ví ảo',
    shezhianquanma:'đặt lại mã bảo mật',
    yuananquanma:'mã bảo mật ban đầu',
    xinanquanma:'mã bảo mật mới',
    planquanma:'Vui lòng nhập mã bảo mật ban đầu',
    plxinanquanma:'Vui lòng nhập mã bảo mật mới',
    queren:'xác nhận',
	quxiao:'Hủy bỏ',
    anquanmaTps:'Vui lòng đặt mã bảo mật, vui lòng không giống với mật khẩu thẻ ngân hàng',
    anquanmaTpsTow:'Kính gửi khách hàng, mã bảo mật này là mật khẩu của bạn khi chuyển ra ngoài, vì lý do bảo mật, vui lòng cố gắng không giống với mật khẩu đăng nhập',
    xiugaidenglu:'Thay đổi mật khẩu đăng nhập',
    jiumima:'Mật khẩu cũ',
    xinmima:'mật khẩu mới',
    querenmima:'Xác nhận mật khẩu',
    pljiumima:'Vui lòng nhập mật khẩu cũ',
    plxinmima:'Vui lòng nhập mật khẩu mới',
    plquerenmima:'vui lòng nhập lại mật khẩu',
    wancheng:'Hoàn thành',
    kaishitouzi:'bắt đầu đầu tư',
    kaiTps:'Khi dòng hiện tại không khả dụng, hãy thử chuyển sang dòng khác',
    zuiyou:'tốt nhất',
    dangaqianxianlu:'dòng hiện tại',
    dangqianbanben:'Phiên bản hiện tại',
    banquansuoyou:'Đã đăng ký Bản quyền',
    yinglizhendong:'Sốc thu nhập',
    gensuixitong:'làm theo hệ thống',
    zhuanchujine:'Số tiền chuyển khoản',
    plzhuanchu:'Vui lòng nhập số tiền chuyển',
    zhuanchuTps:'Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ bộ phận chăm sóc khách hàng',
    xuanzeyh:'Vui lòng chọn thẻ ngân hàng rút tiền',
    xuanzehb:'Vui lòng chọn ví ảo để rút tiền',
    tijiaoshenqing:'nộp đơn',
    shuruanquanma:'Vui lòng nhập mã bảo vệ',
    xianshangcunru:'gửi tiền trực tuyến',
    saomacunru:'USDT',
    wangyincunru:"tiền gửi ngân hàng trực tuyến",
    shijian:'thời gian',
    upshijian:'thời gian bắt đầu',
    downshijian:'Thời gian kết thúc',
    wuTps:'Không có dữ liệu liên quan ~',
    jiazaicx:'tải lại',
    di:'KHÔNG.',
    lun:'bánh xe',
    weiyingli:'Không có lợi',
    yishouli:'Đã được chấp nhận',
    zhuangtai:'tình trạng',
    chongzhi:'cài lại',
    riqi:'ngày',
    CapActive:{
        chongzhi:'nạp điện',
        tixian:'rút',
        goumai:'Mua',
        yingli:'lợi nhuận',
        zengsong:'quà ',
        weitongguo:'không vượt qua',
        yitongguo:'đi qua',
        yijujue:'vật bị loại bỏ',
    },
    zhanneixiaoxi:"tin tức nhà ga",
    pingtaigonggao:'thông báo nền tảng',
    fuzhilanjie:"sao chép đường dẫn",
    denglu:'Đăng nhập',
    jizhumima:'nhớ mật khẩu',
    wangjimima:'quên mật khẩu',
    youkedenglu:'Đăng nhập của khách',
    zhucezhanghao:"Đăng ký ngay",
    plusername:'Vui lòng nhập tên người dùng',
    plpassword:'xin vui lòng nhập mật khẩu',
    wanchengzhuce:'đăng ký hoàn tất',
    yiyouzhanghao:'tài khoản hiện có',
    qingdenglu:'làm ơn đăng nhập',
    zhuce:'đăng ký',
    remenhuati:'chủ đề nóng',
    news1:'nguyên bản',
    news2:'gợi ý',
    news3:'sự giàu có',
    renliulan:'mọi người duyệt',
    hezhi:'và giá trị',
    lunshu:'Số vòng',
    
    做多:'làm nhiều hơn',
    做空:'ngắn',
    多单:'đặt hàng dài',
    多双:'nhiều cặp',
    空单:'đơn đặt hàng trống',
    空双:'trống đôi',
    平单:'trật tự phẳng',
    平双:'đôi phẳng',
	极阴:'cực kỳ u ám',
	极阳:'nắng gắt',
    qi:'Trông chờ',
    juli:'khoảng cách',
    lunjiezhi:'làm tròn lên',
    yifengpan:'đã đóng',
    yiguanbi:'đóng cửa',
    yonghu:'người dùng',
    danlunjiaoyi:'giao dịch một vòng',
    caozuo:'vận hành',
    pljoin:'Tham gia phòng chat thành công',
    pltingshou:'Sản phẩm đã ngừng sản xuất',
    gengou:"Theo dõi mua hàng",
	quedinggengou:"Bạn có chắc chắn muốn theo dõi giao dịch mua không?",
	wanjia:"người chơi",
	leibie:"loại",
	jine:"Lượng tiền",
    gouru:'mua',
    zanweigouru:'chưa mua',
    qigou:'mua',
    gourushuliang:'Số lượng mua',
    changgui:'thông thường',
    shuzi:'chữ số',
    yinglihezhi:'lợi nhuận và giá trị',
    shouyijieshao:'Giới thiệu lợi ích',
    lijicunru:'gửi tiền ngay bây giờ',
    kaihaojieguo:'Mở kết quả số',
    kaihaoqushi:'xu hướng mở số',
    chanpingshuoming:'Mô tả Sản phẩm',
    qingchuhuancun:'xóa bộ nhớ cache',
    youke:'khách du lịch',
	gongxi:'Chúc mừng',
	zai:'hiện hữu',
	yingli:'lợi nhuận',
	xiadanchenggong:'đặt hàng thành công',
	jiazaizhong:'Đang tải...',
	guanbitg:'Đóng cửa, ngừng mua',
	xiangoudl:'Bạn chỉ có thể mua vòng hiện tại',
	liaotiantip:'Hệ thống đã cấm phát ngôn, hãy chọn mua',
	tishi:'gợi ý',
	zhidao:'biết',
	zhuanchusb:'Không thể chuyển ra ngoài',
	zhuanchusbs:'Số dư không đủ, chuyển không thành công',
	buyxq:'chi tiết mua hàng',
	orderNo:'số hóa đơn',
	plan_name:'Thông tin phòng',
	buyxx:'mua thông tin',
	haoxx:'Chi tiết chọn số',
	buysj:'thời gian mua hàng',
	yilou:'bỏ sót',
	fzjqb:'Sao chép vào clipboard',
	chanpin1:'Thời gian mở thưởng: Cứ 3,5 phút là một vòng, mở thưởng lúc 10h00 cùng ngày và kết thúc lúc 02h00 ngày hôm sau.',
	chanpin2:'Có 3 số từ 0-9 trong mỗi vòng và tổng của 3 số là kết quả cuối cùng, bạn có thể đi dài, ngắn, bằng phẳng, đơn, bằng phẳng, cực dương lưỡng cực và các số cho kết quả đầu tư.',
	Tips:{
        wjmm:'Nếu bạn quên mật khẩu, vui lòng liên hệ bộ phận chăm sóc khách hàng để sửa đổi mật khẩu!',
        dlcg:'đăng nhập thành công',
        tjcg:'gửi thành công',
        zanwu:'Không có dữ liệu',
        buyizhi:'Hai mật khẩu không khớp',
        zcbz:'Tài sản của bạn không đủ, hãy gửi tiền',
        pltxje:'Vui lòng nhập số tiền rút',
        zdtx:'Rút tiền tối thiểu 100',
		youke:'Kính gửi khách truy cập, vui lòng hoạt động sau khi đăng ký làm thành viên chính thức.',
		szcg:'thiết lập thành công',
    },
    zaixiankefu:"dịch vụ trực tuyến",
    lianxi:"liên hệ chúng tôi",
    baocun:"cứu",
    genggaitouxiang:"thay đổi hình đại diện",
    xingming: "Tên",
    pleasexingming: "Vui lòng nhập tên thật của bạn"
}
export default vi;