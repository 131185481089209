const zh={
	jiantizhongwen:'简体中文',
    xianlu:'线路',
    dianwei:'当前点位',
    wodezichan:'我的资产',
    touzikuaixun:'投资快讯',
    jiaoyiyonghu:'交易用户',
    jiaoyipinglei:'交易品类',
    danlunhuoli:'单轮获利',
    jiaoyiriqi:'交易日期',
    dengluhouchakan:'登陆后查看',
    shouye:'首页',
    jiaoyidating:'交易大厅',
    yucun:'预存',
    zoushi:'走势',
    wode:'我的',
    zuiditouzi:'最低投资',
    zuidijinru:'最低进入',
    jichulicai:'初级房',
    zishenlicai:'中级房',
    dashilicai:'高级房',
    zhuanjialicai:'至尊房',
	基础理财:'初级房',
    资深理财:'中级房',
    大师理财:'高级房',
    专家理财:'至尊房',
    huiyuanquanyi:'会员权益',
    zuoriyingli:'昨日盈利',
    zongzichan:'总资产',
    chucunjin:'储存金',
    jinriyingli:'今日盈利',
    zhuanchu:'转出',
    cunru:'存入',
    zhanghuxinxni:'账户信息',
    lishizhangdan:'历史账单',
    zijinminxi:'资金明细',
    renwuzhongxin:'任务中心',
    xiaoxigonggao:'消息公告',
    tuijianfenxiang:'推荐分享',
    huodongzhongxin:'活动中心',
    jibenziliao:'基本资料',
    dengluzhanghao:'登录账号',
    nicheng:'昵称',
    shezhi:'设置',
    genggaixianlu:'更改线路',
    guanyuwomen:'关于我们',
    xitongshezhi:'系统设置',
    yuyanshezhi:'语言设置',
    tuichudenglu:'退出登录',
    bangdingyinhangka:'绑定银行卡',
    bangdingxuniqianbao:'绑定虚拟钱包',
    anquanma:'安全码',
    xiugailenglumima:'修改登陆密码',
    yinhangkaguanli:'银行卡管理',
    tianjiayinhangka:'添加银行卡',
    xuniqianbaoguanli:'虚拟钱包管理',
    tianjiaxuniqianbao:'添加虚拟钱包',
    chiakren:'持卡人',
    yinhangkakahao:'银行卡卡号',
    yinhangmingcheng:'银行名称',
    yhkTps:'请绑定持卡人本人的银行卡',
    nixiqianbaodizhi:'虚拟钱包地址',
    xuniqianbaoleixing:'虚拟钱包类型',
    xnqbTps:' 温馨提示：请填写USDT地址类型，如：trc20、erc20、omni ',
    plchikaren:'请输入持卡人',
    plkahao:'请输入持卡人卡号',
    plyinhangmingcheng:'请输入银行名称',
    plqbdz:'请输入虚拟钱包地址',
    plqblx:'请输入虚拟钱包类型',
    shezhianquanma:'设置安全码',
    yuananquanma:'原安全码',
    xinanquanma:'新安全码',
    planquanma:'请输入原安全码',
    plxinanquanma:'請输入新安全码',
    queren:'确认',
	quxiao:'取消',
    anquanmaTps:' 请设置安全码，请勿与银行卡密码相同',
    anquanmaTpsTow:' 尊敬的客户，此安全码是您转出时的密码，为了安全起见，请尽量不要与登录密码相同',
    xiugaidenglu:'修改登录密码',
    jiumima:'旧密码',
    xinmima:'新密码',
    querenmima:'确认密码',
    pljiumima:'请输入旧密码',
    plxinmima:'请输入新密码',
    plquerenmima:'请再次输入密码',
    wancheng:'完成',
    kaishitouzi:'开始投资',
    kaiTps:'当前线路无法使用时，可尝试切换其他线路',
    zuiyou:'最优',
    dangaqianxianlu:'当前线路',
    dangqianbanben:'当前版本',
    banquansuoyou:'版权所有',
    yinglizhendong:'盈利震动',
    gensuixitong:'跟随系统',
    zhuanchujine:'转出金额',
    plzhuanchu:'请输入转出金额',
    zhuanchuTps:'温馨提示：如有疑问请联系平台客服。',
    xuanzeyh:'请选择提款银行卡',
    xuanzehb:'请选择提款虚拟钱包',
    tijiaoshenqing:'提交申请',
    shuruanquanma:'请输入安全码',
    xianshangcunru:'线上存入',
    saomacunru:'USDT',
    wangyincunru:"银行存入",
    shijian:'时间',
    upshijian:'开始时间',
    downshijian:'结束时间',
    wuTps:'暂无相关数据~',
    jiazaicx:'重新加载',
    di:'第',
    lun:'轮',
    weiyingli:'未盈利',
    yishouli:'已受理',
    zhuangtai:'状态',
    chongzhi:'重置',
    riqi:'日期',
    CapActive:{
        chongzhi:'充值',
        tixian:'提现',
        goumai:'购买',
        yingli:'盈利',
        zengsong:'赠送',
        weitongguo:'未通过',
        yitongguo:'已通过',
        yijujue:'已拒绝',
    },
    zhanneixiaoxi:"站内消息",
    pingtaigonggao:'平台公告',
    fuzhilanjie:"复制链接",
    denglu:'登录',
    zhuce:'注册',
    jizhumima:'记住密码',
    wangjimima:'忘记密码',
    youkedenglu:'游客登陆',
    zhucezhanghao:"注册账户",
    plusername:'请输入用户名',
    plpassword:'请输入密码',
    wanchengzhuce:'完成注册',
    yiyouzhanghao:'已有账户',
    qingdenglu:'请登录',
    remenhuati:'热门话题',
    news1:'原创',
    news2:'推荐',
    news3:'财富',
    renliulan:'人浏览',
    hezhi:'和值',
    lunshu:'轮数',

    做多:'做多',
    做空:'做空',
    多单:'多单',
    多双:'多双',
    空单:'空单',
    空双:'空双',
    平单:'平单',
    平双:'平双',
	极阴:'极阴',
	极阳:'极阳',
    qi:'期',
    juli:'距离',
    lunjiezhi:'轮截至',
    yifengpan:'已封盘',
    yiguanbi:'已关闭',
    yonghu:'用户',
    danlunjiaoyi:'单轮交易',
    caozuo:'操作',
    pljoin:'加入聊天室成功',
    pltingshou:'产品已停售',
    gengou:"跟购",
	quedinggengou:"确定跟购吗",
	wanjia:"玩家",
	leibie:"类别",
	jine:"金额",
    gouru:'购入',
    zanweigouru:'暂未购入',
    qigou:'起购',
    gourushuliang:'购入数量',
    changgui:'常规',
    shuzi:'数字',
    yinglihezhi:'盈利和值',
    shouyijieshao:'收益介绍',
    lijicunru:'立即存入',
    kaihaojieguo:'开号结果',
    kaihaoqushi:'开号趋势',
    chanpingshuoming:'产品说明',
    qingchuhuancun:'清除缓存',
    youke:'游客',
	gongxi:'恭喜 ',
	zai:' 在 ',
	yingli:' 盈利 ',
	xiadanchenggong:'下单成功',
	jiazaizhong:'加载中...',
	guanbitg:'已关闭，停止购入',
	xiangoudl:'只能跟购当前轮',
	liaotiantip:'系统已禁言，请选择购入',
	tishi:'提示',
	zhidao:'知道了',
	zhuanchusb:'转出失败',
	zhuanchusbs:'余额不足，转出失败',
	buyxq:'购入详情',
	orderNo:'账单编号',
	plan_name:'厅房信息',
	buyxx:'购入信息',
	haoxx:'选号详情',
	buysj:'购入时间',
	yilou:'遗漏',
	fzjqb:'已复制到粘贴板',
	chanpin1:'开售时间：每3.5分钟为一轮，当日10:00AM开盘，次日02:00AM停盘。',
	chanpin2:'每轮有3个0-9的数字，3个数字相加为最终结果，可以投资结果做多做空平单平双极阳极阴以及数字。',
	Tips:{
        wjmm:'忘记密码请联系客服修改!',
        dlcg:'登录成功',
        tjcg:'提交成功',
        zanwu:'暂无数据',
        buyizhi:'两次密码不一致',
        zcbz:'您的资产不足请存入',
        pltxje:'请输入提现金额',
        zdtx:'最低提现100',
		youke:'尊敬的游客您好：请您注册正式会员后操作。',
		szcg:'设置成功',
    },
    zaixiankefu:"在线客服",
    lianxi:"联系我们",
    baocun:"保存",
    genggaitouxiang:"更改头像",
    xingming:"姓名",
    pleasexingming:"請輸入真实姓名"


    
}
export default zh;