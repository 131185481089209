<template>
  <div class="recharge">
    <div class="settingwarp">

      
      <header>
        <div class="g-header" style="background: rgb(70, 105, 154)">
          <div class="left" @click="changego">
            <van-icon name="arrow-left" color="#fff" size="20px" />
          </div>
          <div class="middle fs-18">{{ $t("zaixiankefu") }}</div>
          <div class="right">
            <div class="btn-save fs-16"></div>
          </div>
        </div>
        <div class="blockHeight"></div>
      </header>

      <div style="text-align: center; margin: 30px auto" v-if="kefuButton">
						<div @click="showkefu">
              <img src="../assets/image/about.png" style="width: 50px">
            </div>	
						<div style="margin-top: 10px;">{{$t("lianxi")}}</div>
			</div>


      <div style="position: relative;" v-if="!kefuButton">
         <ul>
            <li class="menu-title copyText copySignal"  v-for="(item, i) in kefuList" :key="i" @click="jump(item.url)">
                  <img :src="item.img" alt="">
                  <span>{{item.name}}</span>
                  <span style="float: right;margin-right: 20px" id="text">{{item.caption}}</span>
            </li>
           
         </ul>

      </div>

   

      <alert-pop
        :alertType="alertType"
        :Tips="TipsTxt"
        v-if="true"
        @showAletfalse="showAletfalse"
      ></alert-pop>
    </div>
  </div>
</template>

<script>
import AlertPop from "@/components/alert/AlertPop.vue";
import userApi from "@/api/user";
export default {
  name: "recharge",
  components: {
    AlertPop,
  },
  data() {
    return {
      money: this.$t('jiazaizhong'),
      usdt: null,
      yinhang: null,
      kahao: null,
      xingming: null,
      alertType: null,
      TipsTxt: this.$t('Tips.youke'),
      PopAlert: false,
      kefuList:[

      ],
      kefuButton:true
    };
  },
   computed: {
    group_id() {
      return this.$store.state.group_id;
    },
  },
  methods: {
    jump(url){
      window.location.href = url
    },
    showkefu(){
      this.kefuButton = false
    },
    showAletfalse() {
      this.PopAlert = false;
      this.$router.go(-1);
    },
    changego() {
      this.$router.go(-1);
    },
    info() {
      this.$toast.loading({
        message: this.$t('jiazaizhong'),
        forbidClick: true,
        duration: 0,
      });
      userApi
        .recharge()
        .then((data) => {
          console.log(data.data);
          // this.money = data.data.money;
          // this.usdt = data.data.usdt;
          // this.yinhang = data.data.bankname;
          // this.kahao = data.data.banknum;
          // this.xingming = data.data.bankxm;
          this.kefuList = data.data.kefu_list
          this.$toast.clear();
          this.PopAlert = true;
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.msg);
        });
    },
  },
  mounted() {
	console.log(this.group_id);
  },
  created() {
    this.info();
    this.alertType = 0;
    //   if (this.group_id != 1) {
      
    // }
  },
};
</script>
<style lang="less" scoped>

.menu-title{
    width: 100%;
    text-align: left;
    line-height: 40px;
    font-size: 14px;
    padding:0px 15px;
    margin-top: 10px;
}

.menu-title> img {
    width: 25px;
    margin: 10px;
}


</style>