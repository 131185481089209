const jp={
    jiantizhongwen:'日本',
    xianlu:'ライン',
    dianwei:'現在のポイント',
    wodezichan:'私の資産',
    touzikuaixun:'投資情報',
    jiaoyiyonghu: '取引ユーザー',
    jiaoyipinglei: '取引カテゴリ',
    danlunhuoli: '1 ラウンドの利益',
    jiaoyiriqi:'取引日',
    dengluhouchakan: 'ログイン後に表示',
    shouye:'表紙',
    jiaoyidating:'トレーディングフロア',
    yucun:'プリストア',
    zoushi:'傾向',
    wode:'私の',
    zuiditouzi:'最小投資',
    zuidijinru:'最低限のエントリー',
    jichulicai:'プライマリールーム',
    zishenlicai:'中間室',
    dashilicai:'スーペリアルーム',
    zhuanjialicai:'プレミアルーム',
	基础理财:'プライマリールーム',
    资深理财:'中間室',
    大师理财:'スーペリアルーム',
    专家理财:'プレミアルーム',
    huiyuanquanyi:'会員特典',
    zuoriyingli: '昨日の利益',
    zongzichan:'総資産',
    Chucunjin:'ストア ゴールド',
    jinriyingli: '今日の利益',
    zhuanchu:'転送',
    cunru:'入金',
    zhanghuxinxni:'アカウント情報',
    shizhangdan:'歴史的手形',
    zijinminxi:'ファンドの詳細',
    renwuzhongxin: 'タスク センター',
    xiaoxigonggao: 'ニュース発表',
    tuijianfenxiang:'共有をお勧めします',
    huodongzhongxin:'活動センター',
    jibenziliao:'基本情報',
    dengluzhanghao:'ログインアカウント',
    niche:'ニックネーム',
    shezhi:'設定',
    genggaixianlu:'ルート変更',
    guanyuwomen: '私たちについて',
    xitongshezhi: 'システム設定',
    yuyanshezhi:'言語設定',
    tuichudenglu: 'ログアウト',
    bangdingyinhangka: '銀行カードをバインド',
    bangdingxuniqianbao: '仮想ウォレットをバインド',
    anquama:'セキュリティコード',
    xiugailenglumima: 'ログインパスワードの変更',
    yinhangkaguanli: '銀行カード管理',
    tianjiayinhangka:'銀行カードを追加',
    xuniqianbaoguanli:'仮想ウォレット管理',
    tianjiaxuniqianbao:'仮想ウォレットを追加',
    chiakren:'カード所有者',
    yinhangkakahao:'銀行口座番号',
    yinhangmingcheng:'銀行名',
    yhkTps: 'カード所有者自身の銀行カードをバインドしてください',
    nixiqianbaodizhi:'仮想ウォレットアドレス',
    xuniqianbaoleixing:'仮想ウォレット タイプ',
    xnqbTps:' 注意: USDT アドレスの種類 (trc20、erc20、omni など) を入力してください。 ',
    plchikaren:'カード所有者名を入力してください',
    plkahao: '正しい銀行口座番号を入力してください',
    plyinhangmingcheng: '銀行名を入力してください',
    plqbdz: '仮想ウォレットのアドレスを入力してください',
    plqblx: '仮想ウォレットの種類を入力してください',
    shezhianquanma: 'セキュリティ コードをリセット',
    yuananquanma: '元のセキュリティ コード',
    xinanquanma:'新しいセキュリティ コード',
    planquanma: '元のセキュリティ コードを入力してください',
    plxinanquanma: '新しいセキュリティ コードを入力してください',
    queren: '確認',
    quxiao:'キャンセル',
    anquanmaTps: 'セキュリティ コードを設定してください。銀行カードのパスワードと同じにしないでください',
    anquanmaTpsTow: 'お客様各位、このセキュリティ コードは、送金時のパスワードです。セキュリティ上の理由から、ログイン パスワードと同じにならないようにしてください',
    xiugaidenglu: 'ログインパスワードの変更',
    jiumima:'古いパスワード',
    xinmima:'新しいパスワード',
    querenmima: 'パスワードの確認',
    pljiumima: '古いパスワードを入力してください',
    plxinmima: '新しいパスワードを入力してください',
    plquerenmima: 'パスワードをもう一度入力してください',
    wancheng:'完了',
    kaishitouzi: '投資を開始',
    kaiTps: '現在の回線が利用できない場合は、別の回線に切り替えることができます',
    zuiyou:'最適',
    dangaqianxianlu:'現在の行',
    dangqianbanben:'現在のバージョン',
    banquansuoyou:'著作権',
    yinglizhendong:'利益の振動',
    gensuixitong:'システムに従ってください',
    zhuanchujine: 'Transfer Amount',
    plzhuanchu: '送金金額を入力してください',
    zhuanchuTps: 'ご不明な点がございましたら、カスタマー サービスまでお問い合わせください',
    xuanzeyh: '出金用の銀行カードを選択してください',
    xuanzehb: '引き出し用の仮想ウォレットを選択してください',
    tijiaoshenqing:'申し込みを送信',
    shuruanquanma: 'セキュリティ コードを入力してください',
    xianshangcunru: 'オンライン入金',
    saomacunru:'USDT',
    wangyincunru: "オンラインバンキング預金",
    shijian:'時間',
    upshijian:'開始時間',
    downshijian:'終了時間',
    wuTps: '該当するデータがありません～',
    jiazaicx:'リロード',
    di:'th',
    lun:'wheel',
    weiyingli: '不採算',
    yishouli: '受理しました',
    Zhuangtai:'ステータス',
    chongzhi:'リセット',
    riqi:'日付',
    CapActive:{
        chongzhi:'充電',
        tixian:'撤退',
        goumai:'買う',
        yingli:'利益',
        zengsong:'贈り物',
        weitongguo:'合格しませんでした',
        yitongguo:'合格した',
        yijujue:'拒否されました',
    },
    zhanneixiaoxi: "インサイド ニュース",
    pingtaigonggao: 'プラットフォーム発表',
    fuzhilanjie: "リンクをコピー",
    denglu:'ログイン',
    ジズミマ: 'パスワードを記憶',
    wangjimima: 'パスワードを忘れた',
    youkedenglu:'訪問者ログイン',
    zhucezhanghao: "今すぐサインアップ",
    plusername: 'ユーザー名を入力してください',
    plpassword: 'パスワードを入力してください',
    wanchengzhuce: '完全な登録',
    yiyouzhanghao: 'すでにアカウントを持っています',
    qingdenglu: 'ログインしてください',
    zhuce:'登録',
    remenhuati:'ホット トピック',
    news1:'オリジナル',
    news2:'おすすめ',
    news3:'フォーチュン',
    renliulan: '人がブラウズ',
    hezhi: 'および値',
    lunshu:'ラウンド数',
    
    做多:'もっとやる',
    做空:'短い',
    多单:'ロングオーダー',
    多双:'複数のペア',
    空单:'空注文',
    空双:'空のダブル',
    平单:'フラットオーダー',
    平双:'フラットダブル',
	极阴:'どんよりした',
	极阳:'極端な太陽',
    qi:'予想',
    juli:'距離',
    lunjiezhi:'切り上げする',
    yifengpan:'閉まっている',
    yiguanbi:'閉まっている',
    yonghu:'ユーザー',
    danlunjiaoyi:'シングルラウンド取引',
    caozuo:'操作する',
    pljoin:'チャットルームに正常に参加しました',
    pltingshou: 'この製品は製造中止になりました',
    gengou: "購入をフォロー",
    quedinggengou: "購入をフォローアップしてもよろしいですか?",
    wanjia: "プレイヤー",
    leibie: "カテゴリ",
    jine: "量",
    gouru:'購入',
    zanweigouru: 'まだ購入していません',
    qigou:'最低購入額',
    gourhuruliang:'購入数量',
    Changgui:'一般',
    shuzi:'桁',
    yinglihezhi:'利益と価値',
    shouyijieshao:'特典紹介',
    lijicunru: 'すぐに入金',
    kaihaojieguo: '公開番号結果',
    kaihaoqushi: 'オープン ナンバー トレンド',
    chanpingshuoming:'商品説明',
    qingchuhuacun:'キャッシュをクリア',
    youke:'観光客',
	gongxi:'おめでとう ',
	zai:' 存在 ',
	yingli:' 利益 ',
	xiadanchenggong:'正常に注文されました',
	jiazaizhong:'読み込み中...',
	guanbitg:'閉鎖、購入を停止',
	xiangoudl:'現在のラウンドのみ購入できます',
	liaotiantip:'システムはスピーチを禁止しています。購入することを選択してください',
	tishi:'ヒント',
	zhidao:'了解した',
	zhuanchusb:'転送に失敗しました',
	zhuanchusbs:'残高不足、振込失敗',
	buyxq:'購入明細',
    orderNo:'請求書番号',
    plan_name:'お部屋情報',
    buyxx:'購入情報',
    haoxx: '番号選択の詳細',
    buysj:'時間を買う',
	yilou:'省略',
	fzjqb:'クリップボードにコピーしました',
	chanpin1:'開場時間: 3.5 分ごとに 1 ラウンドが行われ、同日の午前 10:00 に開場し、翌日の午前 2:00 に閉場します。 ',
	chanpin2:'各ラウンドには 0 ～ 9 の 3 つの数字があり、3 つの数字の合計が最終的な結果です. ロング、ショート、フラット、シングル、フラット、バイポーラ、ポジティブ、ネガティブ、および数字の結果として行くことができます。投資。',
	Tips:{
        wjmm: 'パスワードを忘れた場合は、カスタマー サービスに連絡して変更してください!',
        dlcg:'ログイン成功',
        tjcg:'送信に成功しました',
        zanwu: 'まだデータがありません',
        buyizhi: '2 つのパスワードが一致しません',
        zcbz: 'あなたの資産が不足しています。入金してください',
        pltxje: '出金額を入力してください',
        zdtx:'最低出金額 100',
        youke:'こんにちは、親愛なる訪問者: 操作する前に、正会員として登録してください。 ',
        szcg:'設定成功',
    },
    zaixiankefu:"オンラインサービス",
    lianxi:"お問い合わせ",
    baocun: "保存",
    genggaitouxiang: "アバターを変更",
    xingming: "名前",
    pleasexingming:"本名を入力してください"
}
export default jp;